<template>
  <el-drawer
    title="房间详情"
    v-model="visibleDrawer"
    size="800px"
    @closed="onClose"
    custom-class="drawer-store-dtl"
  >
    <div style="flex-grow: 1; flex-direction: column;display: flex;" v-loading="loading">
      <el-tabs>
        <el-tab-pane label="详情">
          <el-descriptions>
            <el-descriptions-item label="房号：">
              <b>{{ model.house_no }}</b>
            </el-descriptions-item>
            <el-descriptions-item label="户型：">
              <b>{{ model.huxing.name }}</b>
            </el-descriptions-item>
            <el-descriptions-item label="房东：">
              <b>{{ model.landlord.name }}</b>
            </el-descriptions-item>
            <el-descriptions-item label="分成比例：">
              <b>{{ model.income.decimal_income_rate }}%</b>
            </el-descriptions-item>
            <el-descriptions-item label="签约日期：">
              <b>{{ model.signed_date }}</b>
            </el-descriptions-item>
            <el-descriptions-item label="售卖日期：">
              <b>{{ model.sell_date }}</b>
            </el-descriptions-item>
            <el-descriptions-item label="备注：">
              <b>{{ model.remark }}</b>
            </el-descriptions-item>
            <el-descriptions-item label="创建人：">
              <b>{{ model.emp.chs_name }}</b>
            </el-descriptions-item>
            <el-descriptions-item label="创建日期：">
              <b>{{ model.create_at }}</b>
            </el-descriptions-item>
          </el-descriptions>
          <el-divider>业主费用支出占比</el-divider>
          <div v-for="item in model.group_expenses" :key="item.order_type
">
            <el-divider content-position="left">{{ item.type_text }}</el-divider>
            <el-descriptions>
              <el-descriptions-item
                :label="x.item_name"
                v-for="x in item.expenses"
                :key="x.expense_item_id"
              >{{ x.decimal_ratio }}%</el-descriptions-item>
            </el-descriptions>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作日志">
          <!-- <div style="flex-grow: 1;height: 0px;">
            <el-scrollbar>
              <el-timeline>
                <el-timeline-item
                  placement="top"
                  v-for="(item, index) in tableData.data"
                  :key="index"
                  :timestamp="`${item.create_at} ${item.emp_name}`"
                >
                  <div
                    style="white-space: pre;word-wrap: break-word;white-space: pre-wrap;   "
                    v-html="item.description"
                  ></div>
                </el-timeline-item>
              </el-timeline>
            </el-scrollbar>
          </div>
          <el-pagination
            v-if="tableData.counts > 0"
            @size-change="pageSizeChange"
            @current-change="pageCurrentChange"
            :page-size="query.pageSize"
            layout="total, prev, pager, next"
            :total="tableData.counts"
          ></el-pagination> -->
          <operate-log :primaryKey="id" :code="8001"></operate-log>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import OperateLog from '@/views/components/operate_log_list.vue';
export default {
    components:{
        OperateLog
    },
  data() {
    return  {
      visibleDrawer: false,
      loading: false,
      model: {
        huxing: {},
        landlord: {},
        income: {},
        emp: {},
        group_expenses: [],
      },
      query: {
        pageIndex: 1,
        pageSize: 20,
        module_code: "8001",
      },
      tableData: {
        counts: 0,
      },
    };
  },
  props: ["id"],
  emits: ["close"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.visibleDrawer = true; 
          this.loadDtl();
        //   this.loadLog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/houseStore/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;

            // this.model = {
            //   id: res.data.id,
            //   house_no: item.house_no,
            //   landlord_id: item.landlord_id,
            //   group_id: item.group_id,
            //   income_rule_id: item.income_rule_id,
            //   is_stop: item.is_stop,
            //   group_ids: [item.huxing.shop_id, item.huxing.id],
            //   remark: item.remark,
            // };
            // if (!this.landlordList.find((o) => o.id == item.landlord_id)) {
            //   this.landlordList.push(item.landlord);
            // }
            // this.orderTypeList.forEach((m) => {
            //   m.expenseList.forEach((x) => {
            //     var exp = item.expenses.find(
            //       (o) =>
            //         o.expense_item_id == x.id && o.order_type == m.order_type
            //     );
            //     if (exp) {
            //       x.value = exp.decimal_ratio;
            //       x.decimal_unit_price = exp.decimal_unit_price;
            //     } else {
            //       x.value = 0;
            //       x.decimal_unit_price = "";
            //     }
            //   });
            // });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClose() {
      this.$emit("close");
    },

    // /**
    //  * 加载日志
    //  */
    // loadLog() {
    //   this.query.primary_key = `${this.id}`;
    //   this.$http.post("seller/v1/OperateLog", this.query).then((res) => {
    //     if (res.code == 0) {
    //       this.tableData = res.data;
    //     }
    //   });
    // },

    // /**
    //  * 分页页数更改
    //  */
    // pageSizeChange(val) {
    //   this.query.pageSize = val;
    //   this.loadLog();
    // },
    // /**
    //  * 分页页码更改
    //  */
    // pageCurrentChange(val) {
    //   this.query.pageIndex = val;
    //   this.loadLog();
    // },
  },
};
</script>

<style>
.drawer-store-dtl .el-drawer__body {
  display: flex;
  flex-direction: column;
}

.drawer-store-dtl .el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.drawer-store-dtl .el-tabs__content {
  flex-grow: 1;
}

.drawer-store-dtl .el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>